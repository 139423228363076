export const environment = {
  rootUrl: 'https://mymuenet.dev.kstg.io',
  apiUrl: 'https://mymuenet.dev.kstg.io/graphql',
  wsUrl: 'wss://mymuenet.dev.kstg.io/graphql-sub',
  editorUrl: 'https://editor.mymuenet.dev.kstg.io',
  editorApiUrl: 'https://editor-api.mymuenet.dev.kstg.io',
  authCookieDomain: '.mymuenet.dev.kstg.io',
  name: 'development',
  sentryDSN: null,
  prodMode: false,
  debug: false,
  oneSignalAppID: 'b0e8e7bb-8231-4bab-b24c-0934afb3ba2f',
  oneSignalSafariWebID: 'web.onesignal.auto.45e32f52-047f-48ea-8b6f-5a9d2fcde2db',
  googleMaps: {
    apiKey: 'AIzaSyA7KTvMaPUixDc6waE5HtKsB3wZQ0AD7Dg',
    timeZoneUrl: 'https://maps.googleapis.com/maps/api/timezone/json',
  },
  myMueller: {
    sso: {
      url: 'https://mymueller-testk.mueller-frick.com/o/oauth2/authorize/',
      clientId: 'id-9198a825-3ed5-7fee-c657-eb5c6428808',
    },
  },
};
